<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="true"
    @close='onCallbackClose'
    width="48%"
    :title="`${isHD ? '高清' : '标清'}视频`"
    :visible.sync="isShow"
    append-to-body
  >
  <div>
    <div id="video-container"></div>
     <div class="but">
      <div class="item-arrow">
        <div @mousedown="onStart(2, '左')" @mouseup="onStop">
          <img src="@/assets/video/left.png" alt="" />
        </div>
        <div @mousedown="onStart(3, '右')" @mouseup="onStop">
          <img src="@/assets/video/right.png" alt="" />
        </div>
      </div>
      <div class="item-arrow">
        <div @mousedown="onStart(0, '上')" @mouseup="onStop">
          <img src="@/assets/video/up.png" alt="" />
        </div>
        <div @mousedown="onStart(1, '下')" @mouseup="onStop">
          <img src="@/assets/video/down.png" alt="" />
        </div>
      </div>
      <div class="item-arrow">
        <div @mousedown="onStart(9, '聚焦后')" @mouseup="onStop">
          <img src="@/assets/video/less.png" alt="" />
        </div>
        <div @mousedown="onStart(8, '聚焦前')" @mouseup="onStop">
          <img src="@/assets/video/plus.png" alt="" />
        </div>
      </div>
      <div class="item-arrow">
        <div @click="onCamera()">
          <img src="@/assets/video/camera.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  </el-dialog>
</template>

<script>
/* eslint-disable */
import EZUIKit from 'ezuikit-js'
import { getVideo } from '@/api/device'
export default {
  name: 'showVideo',
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    isHD: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data () {
    return {
      isShow: true,
      result: {},
      dire: 0, // 方向
    }
  },
  created() {
    this._loadData()
  },
  methods: {
    _loadData: function() {
      getVideo(this.id).then(res => {
        const { result } = res
        this.result = result
        console.log(result , '::::asd')
        this.player = new EZUIKit.EZUIKitPlayer({
          autoplay: true,
          id: 'video-container',
          accessToken: result.token,
          url: `ezopen://open.ys7.com/${result.info.unit_member}/${result.info.unit_aisle}${this.isHD ? '.hd' : ''}.live`,
          template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          width: 880,
          height: 500,
        })
      })
    },
    onStart: function (dire) {
      this.dire = dire
      $.ajax({
        // 几个参数需要注意一下
        type: 'POST', // 方法类型
        dataType: 'json', // 预期服务器返回的数据类型
        url: 'https://open.ys7.com/api/lapp/device/ptz/start', // url
        data: {
          accessToken: this.result.token,
          deviceSerial: this.result.info.unit_member,
          channelNo: this.result.info.unit_aisle,
          direction: this.dire,
          speed: 1
        },
        success: function (result) {
          console.log(result) // 打印服务端返回的数据(调试用)
        },
        error: function () {
          alert('异常！')
        }
      })
    },
    onStop: function () {
      $.ajax({
        // 几个参数需要注意一下
        type: 'POST', // 方法类型
        dataType: 'json', // 预期服务器返回的数据类型
        url: 'https://open.ys7.com/api/lapp/device/ptz/stop', // url
        data: {
          accessToken: this.result.token,
          deviceSerial: this.result.info.unit_member,
          channelNo: this.result.info.unit_aisle,
          direction: this.dire,
          speed: 1
        },
        success: function (result) {
          console.log(result) // 打印服务端返回的数据(调试用)
        },
        error: function () {
          alert('异常！')
        }
      })
    },
    // 关闭
    onCallbackClose: function() {
      this.isShow = false
      this.$emit('close')
      this.player.stop()
    },
  },
}
</script>

<style lang="stylus" scoped>
.but {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
}

.item-arrow div {
  width: 40px;
  height: 40px;
  background-color: #1ABEAE;
  border-radius: 2px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 26px;
    height: 26px;
  }
}

.item-arrow div:hover {
  background-color: #1ABEa9;
}
</style>
